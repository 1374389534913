body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* 
:root {
  --primary-color: "#f58b0e";
} */

html {
  font-size: 15px;
}

/* @font-face {
  font-family: "bangers";
  src: url("./assets/fonts/bangers.ttf");
}

@font-face {
  font-family: "helvetica";
  src: url("./assets/fonts/helvetica.otf");
} */
